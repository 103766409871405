
.codeInput {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    justify-content: center;
    max-width: 600px;
    .css-1fyyp8j-MuiGrid-root {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .subtitle {
        color: #283583;
        text-align: center;
        font-family: 'Epilogue', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        margin-bottom: 30px;
    }
}

.illu-container {
    display: flex;
    flex-basis: 100%; 
    justify-content: center;
    overflow: hidden;
    max-width: 100%;
    height: 400px;
    img {
        height: 300px !important;
        position: relative;
        object-fit: cover;
    }
}
.logo-container {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin: 50px 0;
    img {
        height: 40px;
    }
}
.button-primary {
    padding: 6px 35px !important;
    border-radius: 45px !important;
    background-color: #032B7C !important;
    font-weight: bold !important;
    font-family: 'Epilogue', sans-serif !important;
    height: 45px
    
}
