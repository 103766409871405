.validation {
    flex-basis: 100%;
    display: flex;
    max-width: 500px;

    .validation-content {
        flex-basis: 100%;
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .logo {
        display: flex;
        flex-basis: 100%;
        margin-top: 30px;
        justify-content: center;
        img {
            height: 40px;
        }
    }
    h1 {
        flex-basis: 80%;
        color: #283583;
        margin: 30px 0;
        text-align: center;
        font-family: "Epilogue", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px; /* 30.36px */
    }
    
    .store {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        justify-content: center;
        position: relative;
        h2 {
            flex-basis: 100%;
            padding: 0 30px;
            color: #283583;
            margin: 20px 0;
            text-align: center;
            font-family: "Epilogue", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px; /* 25.3px */
        }
    }
    .footer {
        display: flex;
        flex-basis: 100%;
        overflow: hidden;
        margin-top: 100px;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }
}
.arrow {
    position: absolute;
    top: 10px;
    right: 2%;
    
}
@media screen and (min-width: 400px) {
    .validation .footer {
        display: flex;
        flex-basis: 100%;
        overflow: hidden;
        margin-top: 100px;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }
    .arrow {
        position: absolute;
        top: 10px;
        right: 15%;
        
    }
}
