.getUserInfos {
    position: relative;
    overflow: hidden;
    max-width: 550px;
    .getUserInfos-content {
        display: flex;
        flex-basis: 100%;
        margin: 0 auto;
        max-width: 1200px;
        flex-wrap: wrap;
        padding: 40px 20px;
    }
    .forme {
        height: 300px;
        position: absolute;
        top: -50px;
        right: -100px;
        z-index: -1;
    }
    .mockup {
        height: 210px;
        position: absolute;
        top: 130px;
        right: 20px;
    }
    .humans {
        height: 180px;
        margin: 0 auto 40px auto;
    }
    .heading {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .heading-title {
            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;
        }
        .logo {
            margin-top: 30px;
            height: 45px;
        }
        h1 {
            color: #DC6413;
            font-family: 'Epilogue', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            flex-basis: 65%;
            margin: 30px 0 10px 0;
        }
        .subtitle {
            color: #283583;

            text-align: center;
            font-family: "Epilogue", sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px; /* 18.9px */
            flex-basis: 100%;
            justify-content: center;
            > span {
                font-weight: bold;
            }
        }
    }
}
.getUserInfos .user-infos_form {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.20);
    margin: 10px auto;
    padding: 40px 20px;
    border-radius: 16px;
    h2 {
        font-weight: bold;
        position: relative;
        margin-bottom: 20px;
        .underline {
            height: 2px;
            width: 100px;
            position: absolute;
            top: 30px;
            background-color: #283583;
            left: 25%;
        }
    }
}
.getUserInfos .MuiGrid-item {
    padding-left: 0 !important;
    
}
footer {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
}
.footer-heading {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    justify-content: center;
    padding: 10px 0;
    border-bottom: solid 2px #DC6413;
    img {
        height: 20px;
    }
}
.footer-content {
    padding: 20px;
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    flex-basis: 100%;
    .footer-nav {
        flex-direction: column;
        padding-top: 10px;
    } 
    .footer-nav a {
        text-decoration: none;
        color: #283583;
        font-family: "Epilogue", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; 
        
    }
    .footer-nav{
        flex-basis: 60%;
    }
    .socials{
        flex-basis: 35%;
    }
}

.socials {
    h3 {
        color: #283583;
        font-family: "Epilogue", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        margin-bottom: 10px;
    }
    .icons-socials {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
        a {
            background-color: #283583;
            display: flex;
            height: 30px;
            width: 30px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img {
                height: 10px;
            }
        }
    }
}
.copywrite {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    padding: 10px 0;
    p {
        color: #283583;
        font-family: "Epilogue", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }
}
@media screen and (min-width: 599px ) {
    
}